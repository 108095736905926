import React from "react"
import PropTypes from "prop-types"

const icons = {
  star: (
    <path d="M23.88 9.42c0-.63-.48-1.14-1.06-1.14h-.1l-7.29-.18L12.95.83A1.07 1.07 0 0011.94 0c-.48 0-.89.35-1.01.83L8.44 8.1l-7.28.19h-.1C.46 8.28 0 8.78 0 9.41c0 .4.2.76.48.96l5.8 4.6-2.07 7.2a1.2 1.2 0 00-.12.51c0 .64.47 1.15 1.06 1.15a1 1 0 00.65-.25l6.14-4.37 6.14 4.37a1 1 0 00.65.25c.58 0 1.05-.51 1.05-1.15 0-.18-.04-.36-.11-.52l-2.07-7.2 5.8-4.59c.28-.2.48-.55.48-.96" />
  ),
  instagram: (
    <g>
      <path d="M12.1 5.98A6.1 6.1 0 005.95 12a6.07 6.07 0 006.15 6.02A6.1 6.1 0 0018.24 12c0-3.3-2.78-6.02-6.14-6.02zm0 9.88A3.92 3.92 0 018.16 12a3.92 3.92 0 013.94-3.86A3.92 3.92 0 0116.03 12a3.92 3.92 0 01-3.93 3.86z" />
      <ellipse cx="18.48" cy="5.84" rx="1.39" ry="1.36" />
      <path d="M22.08 2.3A6.91 6.91 0 0017.04.39H7.15C2.98.38.2 3.1.2 7.2v9.65a6.7 6.7 0 002.02 5.03 7.08 7.08 0 004.99 1.84H17c2.06 0 3.78-.66 5.03-1.84A6.67 6.67 0 0024 16.9V7.2c0-1.98-.67-3.67-1.92-4.9zm-.2 14.6c0 1.45-.52 2.63-1.38 3.43a5.02 5.02 0 01-3.5 1.22H7.2a5.02 5.02 0 01-3.5-1.22 4.68 4.68 0 01-1.3-3.48V7.2c0-1.41.43-2.59 1.3-3.44a4.94 4.94 0 013.5-1.22h9.89c1.44 0 2.64.42 3.5 1.27a4.8 4.8 0 011.3 3.39v9.7z" />
    </g>
  ),
  facebook: (
    <path d="M.05 21.96c0 1.1.9 1.99 2 1.99h19.9a2 2 0 002-2V2.06a2 2 0 00-2-2H2.06a2 2 0 00-2 2v19.9zm12.45-1V13h-2v-3h2c0-5.12.22-5.47 6.47-5.47v2.99c-3.3 0-2.99.18-2.99 2.49h2.99V13h-2.99v7.96H12.5z" fillRule="evenodd"/>
  ),
  twitter: (
    <path d="M23.95 2.84c-.88.49-1.83.81-2.82.96A6.02 6.02 0 0023.29.45c-.95.7-2 1.2-3.12 1.47A4.54 4.54 0 0016.58 0c-2.71 0-4.91 2.71-4.91 6.06 0 .47.04.93.13 1.38C7.7 7.19 4.09 4.77 1.67 1.1 1.25 2 1 3.04 1 4.15c0 2.1.87 3.96 2.2 5.04-.82-.02-1.58-.3-2.24-.75v.07c0 2.94 1.7 5.38 3.95 5.94a4.08 4.08 0 01-2.22.1c.62 2.4 2.44 4.16 4.6 4.2A8.75 8.75 0 010 21.27 11.98 11.98 0 007.53 24c9.04 0 13.98-9.23 13.98-17.24l-.01-.78c.96-.86 1.79-1.92 2.45-3.14z" fillRule="nonzero"/>
  )
  
}

const Icon = ({ icon, stroke, fill }) => (
  <svg
    fill={fill ? fill : "none"}
    height="24"
    stroke={stroke ? stroke : "currentColor"}
    strokeLinecap="round"
    strokeLinejoin="round"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {icons[icon]}
  </svg>
)

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  fill: PropTypes.string,
}

export default Icon
